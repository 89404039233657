import React, { useRef, useState, useEffect } from "react";
import { useStore } from "../store";
import "../css/top.scss";
import { UpCircleOutlined, DownCircleOutlined } from "@ant-design/icons";

function Top(props) {
  const openNotice = useStore((state) => state.openNotice);
  const horizon = useStore((state) => state.horizon);
  const setDeep = useStore((state) => state.setDeep);

  return <div className={!openNotice.open ? "topWrap" : "topWrapHide"}></div>;
}

export default Top;
