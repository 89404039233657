import React, { useRef, useEffect, useMemo, Suspense } from "react";
import { extend, useFrame, useLoader } from "react-three-fiber";
import Dummy from "./Dummy";
import Rich from "./Rich";
import Duk from "./Duk";
import Young from "./Young";
import Jun from "./Jun";
import Island from "./Island";
import Nugu from "./Nugu";
import Digverse from "./Digverse";
import DukPF from "./DukPF";
import { useStore } from "../../store";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import snowUrl from "./snowman.glb";

function Snowman({ position, ...props }) {
  const obj = useLoader(GLTFLoader, snowUrl);
  const scene = useMemo(() => {
    return obj?.scene?.clone(true);
  }, [obj]);

  return (
    <Suspense fallback={null}>
      <primitive object={scene} position={position} {...props} />
    </Suspense>
  );
}

function Objects(props) {
  const horizon = useStore((state) => state.horizon);
  const history = useStore((state) => state.history);

  return (
    <>
      <Dummy />
      <Dummy />
      <Duk />
      <Rich />

      {history.young && <Young />}
      {history.jun && <Jun />}
      {history.island && <Island />}
      {history.nugu && <Nugu />}
      <group position={[-11.5, 5, -14.5]}>{history.nugu && <Digverse />}</group>
      <group position={[-11.5, 5, -14.5]}>{history.nugu && <DukPF />}</group>

      {history.structure && (
        <>
          {/* <Snowman scale={[0.01, 0.01, 0.01]} position={[30, 7.5, 0]} rotation={[0, Math.PI / 2, 0]} /> */}
          <mesh position={[30, 0, 0]} rotation={[0, Math.PI * 2.5, 0]} receiveShadow>
            <torusBufferGeometry args={[6, 2, 10, 50]} attach="geometry" />
            <meshBasicMaterial color="yellow" attach="material" />
          </mesh>
          <mesh position={[-35, 0, -35]} rotation={[Math.PI * -0.12, Math.PI * 1.5, 0]} receiveShadow>
            <boxBufferGeometry args={[4, 15, 4]} attach="geometry" />
            <meshBasicMaterial color="yellow" attach="material" />
          </mesh>
        </>
      )}
    </>
  );
}

export default Objects;
