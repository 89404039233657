import React, { Suspense, useRef, useEffect, useState, useMemo } from "react";
import "./css/app.scss";
import "./css/default.scss";
import * as THREE from "three";
import { Canvas, extend, useFrame, useThree } from "react-three-fiber";
import Pool from "./components/oris/Pool";
import { useStore } from "./store";
// import { OrbitControls, HTML } from "drei";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import Top from "./components/top";

import Footer from "./components/footer";
import Notice from "./components/notice";
import Projects from "./components/projects";
import ProjectsDigverse from "./components/projectsDigverse";
import UnderWater from "./components/UnderWater";

extend({ OrbitControls });

function Init({ parentRef }) {
  const { camera, gl } = useThree();
  const orbit = useRef();
  const openNotice = useStore((state) => state.openNotice);
  const setHorizon = useStore((state) => state.setHorizon);
  const setOpenNotice = useStore((state) => state.setOpenNotice);
  useFrame(() => {
    camera.updateProjectionMatrix();
    if (orbit.current.getPolarAngle() > 1.6) {
      setHorizon(true);
      camera.zoom = 0.5;
    } else {
      camera.zoom = 1;
      setHorizon(false);

      // if (orbit.current.target.distanceTo(orbit.current.object.position) < 11) {
      //   if (!openNotice.open && !openNotice.isZoom && openNotice.target !== "ZOOM") {
      //     setOpenNotice({ open: true, target: "", isZoom: false });
      //   } else if (openNotice.isZoom) {
      //     setOpenNotice({ open: false, target: "ZOOM", isZoom: false });
      //   }
      // } else {
      //   setOpenNotice({ open: false, target: "", isZoom: false });
      // }
    }
  });
  return (
    <orbitControls
      enableDamping={true}
      dampingFactor={0.25}
      rotateSpeed={0.85}
      args={[camera, parentRef.current]}
      ref={orbit}
      enablePan={false}
      minDistance={10.0}
      maxDistance={60.0}
      zoomSpeed={2.5}
    />
    // <OrbitControls
    //   args={[camera, gl.domElement.querySelector(".parent")]}
    //   ref={orbit}
    //   enableDamping={true}
    //   dampingFactor={0.25}
    //   rotateSpeed={0.4}
    //   keyPanSpeed={0.4}
    //   // screenSpacePanning={true}
    //   // enabled={false}
    //   zoomSpeed={0.6}
    //   enablePan={false}
    //   panSpeed={0.4}
    //   maxDistance={60.0}
    //
    //   // maxPolarAngle={Math.PI / 2}
    // />
  );
}
function App() {
  const parentRef = useRef();
  const horizon = useStore((state) => state.horizon);
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    function handleWindowSizeChange() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return (
    <>
      <Projects />
      <ProjectsDigverse />
      <Notice />
      <div className="parent" ref={parentRef}>
        <Canvas
          camera={width < 500 ? { position: [55.16, 1.4, 1.3] } : { position: [47.3, 0.8, 0.4] }}
          className={!horizon ? "underCanvas" : "upCanvas"}
          pixelRatio={window.devicePixelRatio}
        >
          <Init parentRef={parentRef} />
          <fog attach="fog" args={[new THREE.Color("#32bce6"), 0, 200]} />
          <pointLight position={[0, -100, 0]} distance={150} intensity={3} color="white" />
          <pointLight position={[10, 15, 10]} />
          <hemisphereLight args={[0xaaaaaa, 0x000000, 1.5]} />
          {/* <ambientLight args={[0x999999, 0.1]} /> */}
          <ambientLight args={["#067380", 0.02]} />
          <Suspense fallback={null}>
            <Pool />
          </Suspense>
        </Canvas>
        <div className="contextWrap">
          <UnderWater />
        </div>
      </div>
      <Footer />
    </>
  );
}
export default App;
