import create from "zustand";

export const [useStore] = create((set) => ({
  count: 0,
  targetObject: null,
  focusObject: null,
  openProfile: false,
  profileTarget: "",
  deep: true,
  horizon: false,
  openNotice: { open: false, target: "", isZoom: false },
  openProject: { open: false },
  openProjectDigverse: { open: false },
  workFocus: 1,
  lineAi: [],
  history: {
    young: true,
    jun: true,
    island: true,
    nugu: true,
    structure: true,
    selnum: 6,
    show: false,
  },
  target: (o) => set((state) => ({ targetObject: o })),
  setDeep: (o) => set((state) => ({ deep: !state.deep })),
  setWorkFocus: (o) => set((state) => ({ workFocus: o })),
  setOpenNotice: (o) => set((state) => ({ openNotice: { open: o.open, target: o.target, isZoom: o.isZoom } })),
  setOpenProject: (o) => set((state) => ({ openProject: { open: o.open } })),
  setOpenProjectDigverse: (o) => set((state) => ({ openProjectDigverse: { open: o.open } })),

  setLineAi: (o) => set((state) => ({ lineAi: o })),
  setFocus: (o) => set((state) => ({ focusObject: o })),
  setHorizon: (o) => set((state) => ({ horizon: o })),
  setOpenProfile: (o) => set((state) => ({ openProfile: !state.openProfile, profileTarget: o })),
  increase: () => set((state) => ({ count: state.count + 1 })),
  setHistory: (o) =>
    set((state) => ({
      history: {
        young: o.young,
        jun: o.jun,
        island: o.island,
        nugu: o.nugu,
        structure: o.structure,
        selnum: o.selnum,
        show: o.show,
      },
    })),
  reset: () => set({ count: 0 }),
}));

//openProfile / setOpenProfile => Click
//setFocus
