import React, { useRef, useEffect, useMemo, Suspense } from "react";
import { extend, useFrame, useLoader } from "react-three-fiber";
import { Water } from "three/examples/jsm/objects/Water";
import Objects from "./Objects";
import Particles from "./Particles";
import { useStore } from "../../store";
import * as THREE from "three";
extend({ Water });

function Pool(props) {
  const waters = useRef();
  const waterBox = useRef();
  const horizon = useStore((state) => state.horizon);
  const waterMap = useMemo(() =>
    new THREE.TextureLoader().load("img/waternormals.jpg", function (texture) {
      texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
    })
  );
  useEffect(() => {
    waterBox.current.rotation.x = Math.PI / 2;
    waterBox.current.position.y = -4.55;
  }, []);

  useFrame(() => {
    if (waters.current !== null) {
      waters.current.material.uniforms["time"].value += 2.0 / 600.0;
    }
  });

  return (
    <>
      <Objects />
      <Particles count={200} />

      <mesh ref={waterBox}>
        <boxGeometry attach="geometry" args={[500, 500, 7.5]} />
        <meshStandardMaterial attach="material" color="#029ffa" transparent={true} opacity={0.75} />
      </mesh>
      {/* <mesh position={[0, -10, 0]}>
        <boxGeometry attach="geometry" args={[500, 2, 500]} />
        <meshStandardMaterial attach="material" color="#029ffa" transparent={true} opacity={0.75} />
      </mesh> */}
      <group rotation={[-Math.PI / 2, 0, 0]} position={[0, -1, 0]}>
        <water
          ref={waters}
          args={[
            new THREE.PlaneBufferGeometry(500, 500, 1, 1),
            {
              textureWidth: 450,
              textureHeight: 450,
              waterNormals: waterMap,
              alpha: 1,
              waterColor: new THREE.Color("#134554"),
              distortionScale: 0.5,
              fog: true,
            },
          ]}
        />
      </group>
    </>
  );
}

export default Pool;
