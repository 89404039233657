import React, { useRef, useState, useEffect } from "react";
import { useFrame } from "react-three-fiber";
import * as THREE from "three";
import { HTML } from "drei";
import { useStore } from "../../store";

function Duk(props) {
  const all = useRef();
  const body = useRef();
  const head = useRef();
  let timer = 0;
  let pos = new THREE.Vector3(0, 0, 0);
  let vel = new THREE.Vector3(0, 0, 0);
  const [hovered, setHover] = useState(false);
  const [active, setActive] = useState(true);
  const setOpenNotice = useStore((state) => state.setOpenNotice);
  const openNotice = useStore((state) => state.openNotice);
  const openProject = useStore((state) => state.openProject);
  const target = useStore((state) => state.target);
  const focusObject = useStore((state) => state.focusObject);
  const horizon = useStore((state) => state.horizon);
  useEffect(() => {
    all.current.position.x = -12.5;
    all.current.position.z = 3;
    all.current.rotation.y = Math.PI / 2;
  }, []);

  useEffect(() => {
    if (focusObject === null) {
      setActive(false);
    }
  }, [focusObject]);

  useEffect(() => {
    if (hovered) {
      target(head.current.quaternion);
    } else {
      target(null);
    }
  }, [hovered]);
  useFrame(() => {
    if (!focusObject && !horizon && all.current !== null) {
      timer += 0.05;
      let roC = Math.sin(timer) * 0.05;
      let roCA = Math.sin(timer * 10) * 0.05;

      head.current.rotation.x = Math.PI / 4 + roCA * 2.5;
      head.current.rotation.y = roC * 7.5;
    }
  });

  const Box = React.forwardRef(
    ({ rotation, children, color = "rgb(252, 186, 3)", transparent = false, opacity = 1, args = [1, 1, 1], ...props }, ref) => {
      return (
        <mesh receiveShadow ref={ref} {...props} rotation={rotation}>
          <boxBufferGeometry attach="geometry" args={args} />
          <meshStandardMaterial attach="material" color={color} transparent={transparent} opacity={opacity} />
        </mesh>
      );
    }
  );

  return (
    <group rotateY={Math.PI / 2} ref={all} onPointerDown={() => setOpenNotice({ open: true, target: "duk" })}>
      {!horizon && (
        <HTML className={!openNotice.open && !openProject.open ? "contentRichWrap" : "contentRichWrapHide"} scaleFactor={20} position={[0, 2, 0]}>
          <div
            className={focusObject !== null ? "contentName Sel" : "contentName UnSel"}
            onPointerDown={() => setOpenNotice({ open: true, target: "duk" })}
          >
            <p>MAKER</p>
            <p>Duk</p>
          </div>
        </HTML>
      )}
      <Box position={[0, -0.75, -1]} scale={[1.5, 1, 1.8]} ref={body} />
      <group ref={head} onPointerOver={(e) => setHover(true)} onPointerOut={(e) => setHover(false)} rotation={[Math.PI / 5, 0, 0]}>
        <Box position={[0, 0, 0]} scale={[1, 1, 1]} />
        {/* EyeHair */}
        <Box position={[0, 0.3, 0.55]} scale={[0.75, 0.1, 0.2]} color="#222324" />
        {/* Eyes */}
        <Box position={[-0.25, 0.1, 0.5]} scale={[0.15, 0.15, 0.15]} color="#222324" />
        <Box position={[0.25, 0.1, 0.5]} scale={[0.15, 0.15, 0.15]} color="#222324" />
        {/* Lips */}
        <Box position={[0, -0.2, 0.55]} scale={[0.55, 0.18, 0.55]} color="#f04f4f" />
      </group>
    </group>
  );
}

export default Duk;
