import React, { useRef, useState, useEffect, Suspense, useMemo } from "react";
import * as THREE from "three";
import { useFrame } from "react-three-fiber";
import { HTML } from "drei";
import { useStore } from "../../store";
import { useLoader } from "react-three-fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import hatUrl from "./hat2.glb";

function Hat2({ position, ...props }) {
  const obj = useLoader(GLTFLoader, hatUrl);
  const scene = useMemo(() => {
    return obj?.scene?.clone(true);
  }, [obj]);

  return (
    <Suspense fallback={null}>
      <primitive object={scene} position={position} {...props} />
    </Suspense>
  );
}
function Rich(props) {
  const all = useRef();
  const body = useRef();
  const head = useRef();
  const hair = useRef();
  const quest = useRef();
  let timer = 0;
  let pos = new THREE.Vector3(0, 0, 0);
  let vel = new THREE.Vector3(0, 0, 0);
  const [hovered, setHover] = useState(false);
  const [active, setActive] = useState(true);
  const setOpenNotice = useStore((state) => state.setOpenNotice);
  const openProject = useStore((state) => state.openProject);
  const openNotice = useStore((state) => state.openNotice);
  const target = useStore((state) => state.target);
  const focusObject = useStore((state) => state.focusObject);
  const horizon = useStore((state) => state.horizon);

  useEffect(() => {
    if (focusObject === null) {
      setActive(false);
    }
  }, [focusObject]);

  useEffect(() => {
    if (hovered) {
      target(head.current.quaternion);
    } else {
      target(null);
    }
  }, [hovered]);
  useFrame(() => {
    if (!focusObject && !horizon && all.current !== null) {
      //moving
      let ranX = Math.floor(Math.random() * 10) + 1;
      ranX *= Math.floor(Math.random() * 2) == 1 ? 1 : -1;
      ranX *= 0.01;
      let ranY = Math.floor(Math.random() * 10) + 1;
      ranY *= Math.floor(Math.random() * 2) == 1 ? 1 : -1;
      ranY *= 0.01;

      vel = new THREE.Vector3(ranX, 0, ranY);
      pos.add(vel);
      all.current.position.lerp(pos, 0.01);
      timer += 0.05;
      let roC = Math.sin(timer) * 0.05;
      let roS = Math.cos(timer) * 0.03;
      all.current.rotation.z = roC;
      all.current.rotation.x = roS;
    } else {
      if (all.current !== null) {
        all.current.position.x = all.current.position.x += 0.0001;
      }
    }
  });

  const Box = React.forwardRef(
    ({ rotation, children, color = "rgb(252, 186, 3)", transparent = false, opacity = 1, args = [1, 1, 1], ...props }, ref) => {
      return (
        <mesh receiveShadow ref={ref} {...props} rotation={rotation} castShadow={false}>
          <boxBufferGeometry attach="geometry" args={args} />
          <meshStandardMaterial attach="material" color={color} transparent={transparent} opacity={opacity} />
        </mesh>
      );
    }
  );

  const Hair = React.forwardRef(({ rotation, children, color = "black", transparent = false, opacity = 1, args = [1, 30, 30], ...props }, ref) => {
    return (
      <mesh receiveShadow ref={ref} {...props} rotation={rotation}>
        <sphereBufferGeometry attach="geometry" args={args} />
        <meshStandardMaterial attach="material" color={color} transparent={transparent} opacity={opacity} />
      </mesh>
    );
  });

  return (
    <group ref={all} onPointerDown={() => setOpenNotice({ open: true, target: "rich" })}>
      <Hat2 scale={[0.04, 0.04, 0.04]} position={[0, 0.75, 0]} rotation={[0, Math.PI / 2, -Math.PI * 0.1]} />
      <group>
        {/* <mesh position={[0, 3.3, 0]}>
          <boxBufferGeometry attach="geometry" args={[0.3, 1, 0.3]} />
          <meshStandardMaterial attach="material" color="yellow" />
        </mesh>
        <mesh position={[0, 2.2, 0]} onClick={() => setOpenNotice({ open: true, target: "rich" })}>
          <boxBufferGeometry attach="geometry" args={[0.25, 0.25, 0.25]} />
          <meshStandardMaterial attach="material" color="yellow" />
        </mesh> */}
      </group>
      {!horizon && (
        <HTML className={!openNotice.open && !openProject.open ? "contentRichWrap" : "contentRichWrapHide"} scaleFactor={35} position={[1, 2, 0]}>
          <div
            className={focusObject !== null ? "contentName Sel" : "contentName UnSel"}
            onPointerDown={() => setOpenNotice({ open: true, target: "rich" })}
          >
            <p>CEO</p>
            <p>Rich</p>
          </div>
        </HTML>
      )}
      <Box position={[0, -0.75, -1]} scale={[1.5, 1, 2]} ref={body} />
      <group ref={head} onPointerOver={(e) => setHover(true)} onPointerOut={(e) => setHover(false)}>
        <Box position={[0, 0, 0]} scale={[1, 1, 1]} />
        {/* EyeHair */}
        <Box position={[0, 0.3, 0.55]} scale={[0.75, 0.1, 0.2]} color="#222324" />
        {/* Eyes */}
        <Box position={[-0.25, 0.1, 0.5]} scale={[0.15, 0.15, 0.15]} color="#222324" />
        <Box position={[0.25, 0.1, 0.5]} scale={[0.15, 0.15, 0.15]} color="#222324" />
        {/* Lips */}
        <Box position={[0, -0.2, 0.55]} scale={[0.55, 0.18, 0.55]} color="#f04f4f" />
        <group ref={hair}>
          {/* Hair - Back */}
          <Hair position={[0, 0.75, -0.25]} scale={[0.75, 0.75, 0.75]} color="#222324" />
          {/* Hair - front */}
          <Hair position={[0, 0.9, 0.35]} scale={[0.55, 0.55, 0.55]} color="#222324" />
          {/* Hair - front-left */}
          <Hair position={[-0.45, 0.75, 0.35]} scale={[0.4, 0.4, 0.4]} color="#222324" />
          {/* Hair - front-right */}
          <Hair position={[0.45, 0.75, 0.35]} scale={[0.4, 0.4, 0.4]} color="#222324" />
        </group>
      </group>
    </group>
  );
}

export default Rich;
