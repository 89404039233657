import React, { useMemo, Suspense } from "react";
import { extend, useLoader } from "react-three-fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import duckUrl from "./test.glb";

function Duck({ position, ...props }) {
  const obj = useLoader(GLTFLoader, duckUrl);
  const scene = useMemo(() => {
    return obj?.scene?.clone(true);
  }, [obj]);

  return (
    <Suspense fallback={null}>
      <primitive object={scene} position={position} {...props} />
    </Suspense>
  );
}

function Island() {
  return (
    <>
      <Duck scale={[1.75, 1.75, 1.75]} position={[-7.5, -1.25, -30]} rotation={[0, Math.PI * 3, 0]} />
    </>
  );
}

export default Island;
