import React, { useRef, useState, useEffect } from "react";
import { useStore } from "../store";
import { Drawer } from "antd";
import "../css/notice.scss";
import "antd/dist/antd.css";
import { HomeOutlined } from "@ant-design/icons";
import nugu from "./oris/img/nugu.png";
import nugugif1 from "./oris/img/nugugif1.gif";
import nugugif2 from "./oris/img/nugugif2.gif";
function Projects(props) {
  const openProject = useStore((state) => state.openProject);
  const setOpenProject = useStore((state) => state.setOpenProject);
  const onClose = () => {
    setOpenProject({ open: false });
  };
  return (
    <>
      <Drawer placement="right" closable={false} onClose={onClose} visible={openProject.open}>
        <div className="noticeWrap">
          <div className="noticeBody">
            <div className="profileWrap">
              <div className="name">
                <p className="nameTitle">NUGU CONFERENCE</p>
                <p className="namePosition">2020.10.01 - 2020.10.31</p>
              </div>
              <p className="projectImgWrap">
                <img src={nugu} />
              </p>
              {/* <p className="projectImgWrap">
                <img src={nugugif1} />
              </p> */}
              <p className="projectImgWrap">
                <img src={nugugif2} />
              </p>
              <p>Web services that move beyond physical limits in virtual space and respond in real time on PCs and mobile devices.</p>
              <p>
                Construct an imaginary city that objectifies the new technology of artificial intelligence speaker NUGU to express technology
                services.
              </p>
              <p style={{ marginTop: "20px" }}>CLIENT: SK TELECOM</p>
              <p>TECH STACK: Docker | WebGL | Three.js + cannon.js</p>
              <p>
                SITE:
                <a href="https://nuguvirtual.web.app" target="_blank">
                  <span className="linkicon">
                    <HomeOutlined />
                    _NUGU CONFERENCE2020
                  </span>
                </a>
              </p>
            </div>
          </div>
        </div>
      </Drawer>
    </>
  );
}

export default Projects;
