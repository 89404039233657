import React, { useRef, useState, useEffect } from "react";
import * as THREE from "three";
import { useFrame } from "react-three-fiber";
import { HTML } from "drei";
import { useStore } from "../../store";

function Jun(props) {
  const all = useRef();
  const body = useRef();
  const head = useRef();
  const hair = useRef();
  const quest = useRef();
  let timer = 0;
  let pos = new THREE.Vector3(0, 0, 0);
  let vel = new THREE.Vector3(0, 0, 0);
  const [hovered, setHover] = useState(false);
  const [active, setActive] = useState(true);
  const setOpenNotice = useStore((state) => state.setOpenNotice);
  const openNotice = useStore((state) => state.openNotice);
  const openProject = useStore((state) => state.openProject);
  const target = useStore((state) => state.target);
  const focusObject = useStore((state) => state.focusObject);
  const horizon = useStore((state) => state.horizon);
  useEffect(() => {
    if (focusObject === null) {
      setActive(false);
    }
  }, [focusObject]);

  useEffect(() => {
    if (hovered) {
      target(head.current.quaternion);
    } else {
      target(null);
    }
  }, [hovered]);
  useFrame(() => {
    if (all.current) {
      timer += 0.025;
      all.current.position.y = -1.125 + Math.cos(timer) * -0.15;
      // all.current.rotation.y = timer * 0.1;
    }
  });

  return (
    <group ref={all} scale={[0.25, 0.25, 0.25]} onPointerDown={() => setOpenNotice({ open: true, target: "jun" })} position={[-5, 0, 15]}>
      {!horizon && (
        <HTML className={!openNotice.open && !openProject.open ? "contentRichWrap" : "contentRichWrapHide"} scaleFactor={20} position={[0, 0, -10]}>
          <div
            className={focusObject !== null ? "contentName Sel" : "contentName UnSel"}
            onPointerDown={() => setOpenNotice({ open: true, target: "young" })}
          >
            <p>DEVELOPER</p>
            <p>Jun</p>
          </div>
        </HTML>
      )}
      {/* <mesh position={[0, -490, -15]}>
        <boxBufferGeometry args={[1, 1000, 1]} attach="geometry" />
        <meshBasicMaterial color="red" attach="material" />
      </mesh> */}
      <mesh position={[0, 0, -15]} rotation={[Math.PI / 2, Math.PI * -0.05, 0]} receiveShadow>
        <torusBufferGeometry args={[6, 2, 10, 50]} attach="geometry" />
        <meshBasicMaterial color="red" attach="material" />
      </mesh>
      <mesh position={[0, 0, -15]} rotation={[Math.PI / 2, Math.PI * -0.05, 0]} receiveShadow>
        <torusBufferGeometry args={[5, 1.5, 12, 50]} attach="geometry" />
        <meshBasicMaterial color="green" attach="material" />
      </mesh>
      <mesh position={[0, 0, -15]} rotation={[Math.PI / 2, Math.PI * -0.05, 0]} receiveShadow>
        <torusBufferGeometry args={[3, 1.5, 12, 50]} attach="geometry" />
        <meshBasicMaterial color="#0ABEFA" attach="material" />
      </mesh>
    </group>
  );
}

export default Jun;
