import React, { useRef, useState, useEffect } from "react";
import { useStore } from "../store";
import { Drawer } from "antd";
import "../css/notice.scss";
import "antd/dist/antd.css";
import rich from "./oris/img/rich.svg";
import duk from "./oris/img/duk.svg";
import young from "./oris/img/young.svg";
import jun from "./oris/img/jun.svg";

function Notice(props) {
  const openNotice = useStore((state) => state.openNotice);
  const setOpenNotice = useStore((state) => state.setOpenNotice);
  const onClose = () => {
    setOpenNotice({ open: false, target: "char", isZoom: true });
  };
  return (
    <>
      <Drawer placement="right" closable={false} onClose={onClose} visible={openNotice.open}>
        <div className="noticeWrap">
          <div className="noticeBody">
            <div className="profileWrap">
              <div className="name">
                <p className="nameTitle">RICH</p>
                <p className="namePosition">CEO</p>
                <img src={rich} />
              </div>
              <p>
                Rich is well-versed in various aspects of sales & marketing, business strategy and growth, management, manufacturing management, SCM
                and international business, and international trade over 10 years. He is fully committed to enabling innovation, accelerating digital
                transformation for customers, empowering people and societies through new technology
              </p>
            </div>
            <div className="profileWrap">
              <div className="name">
                <p className="nameTitle">DUK</p>
                <p className="namePosition">MAKER</p>
                <img src={duk} />
              </div>
              <p>
                At the intersection of computer interaction, fabrication, pedagogy, and games, Duk explores profound ideas through fast prototyping
                with diverse mediums. His work process is based on co-merging two extremes, ‘softness’ and ‘hardness’. He always says
                <br></br>
                <em>“ Let’s make and play! ”</em>
              </p>
            </div>
            <div className="profileWrap">
              <div className="name">
                <p className="nameTitle">YOUNG</p>
                <p className="namePosition">CREATIVE DIRECTOR</p>
                <img src={young} />
              </div>
              <p>
                With a background in Spatial Design, Young suggests how to blur a dividing line between digital and analogue realms and directs
                valuable digital contents in augmented space. She has been engaged in her passion for augmented space through various projects
                including Moncler, Tiffany & Co, Estee Lauder group, Ericsson and SK Telecom.
              </p>
            </div>
            <div className="profileWrap">
              <div className="name">
                <p className="nameTitle">JUN</p>
                <p className="namePosition">DEVELOPER</p>
                <img src={jun} />
              </div>
              <p>
                Jun is interested in learning risk engineering and visualizing its relevant data to make a robust decision making process in a chaotic
                world. His research is based on an idea that survival depends on an ability to understand different responses from nonliving and
                living things. He tries to benefit from volatile environments in both financial market and personal life.
              </p>
            </div>
          </div>
        </div>
      </Drawer>
    </>
  );
}

export default Notice;
