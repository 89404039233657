import React, { useRef, useState, useEffect } from "react";
import * as THREE from "three";
import { useFrame } from "react-three-fiber";
import { HTML } from "drei";
import { useStore } from "../../store";

function Dummy(props) {
  const horizon = useStore((state) => state.horizon);

  return <group>{!horizon && <HTML scaleFactor={0}></HTML>}</group>;
}

export default Dummy;
