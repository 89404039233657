import React from "react";
import { useStore } from "../../store";
import { HTML } from "drei";

function DukPF() {
  const setOpenProject = useStore((state) => state.setOpenProjectDigverse);
  const openNotice = useStore((state) => state.openNotice);
  const openProject = useStore((state) => state.openProject);
  const focusObject = useStore((state) => state.focusObject);
  const horizon = useStore((state) => state.horizon);
  const openPF = () => {
    window.open("https://myungdukchung.web.app/");
  };
  return (
    <>
      {!horizon && (
        <group position={[7.5, -4, -25]} onPointerDown={() => openPF()}>
          <HTML className={!openNotice.open && !openProject.open ? "contentRichWrap" : "contentRichWrapHide"} scaleFactor={40} position={[2, 2, 0]}>
            <div className={focusObject !== null ? "contentName Sel" : "contentName UnSel"} onPointerDown={() => openPF()}>
              <p>Sand Scape</p>
            </div>
          </HTML>
        </group>
      )}
      <group>
        <mesh position={[10.5, -4, -25]} receiveShadow={false} scale={[2, 2, 2]}>
          <sphereBufferGeometry args={[0.75, 5, 5]} attach="geometry" />
          <meshBasicMaterial color="black" attach="material" />
        </mesh>
      </group>
    </>
  );
}

export default DukPF;
