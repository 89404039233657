import React, { useRef, useState, useEffect } from "react";
import Slider, { Range } from "rc-slider";
import { ClockCircleOutlined, CloseOutlined } from "@ant-design/icons";
import "rc-slider/assets/index.css";
import { useStore } from "../store";

function Footer(props) {
  const horizon = useStore((state) => state.horizon);
  const history = useStore((state) => state.history);
  const setHistory = useStore((state) => state.setHistory);
  const openProject = useStore((state) => state.openProject);
  const openNotice = useStore((state) => state.openNotice);

  const handleChange = (e) => {
    if (e === 1) {
      setHistory({
        young: false,
        jun: false,
        island: false,
        nugu: false,
        structure: false,
        selnum: 1,
        show: true,
      });
    } else if (e === 2) {
      setHistory({
        young: false,
        jun: false,
        island: true,
        nugu: false,
        structure: false,
        selnum: 2,
        show: true,
      });
    } else if (e === 3) {
      setHistory({
        young: true,
        jun: false,
        island: true,
        nugu: false,
        structure: false,
        selnum: 3,
        show: true,
      });
    } else if (e === 4) {
      setHistory({
        young: true,
        jun: false,
        island: true,
        nugu: true,
        structure: false,
        selnum: 4,
        show: true,
      });
    } else if (e === 5) {
      setHistory({
        young: true,
        jun: true,
        island: true,
        nugu: true,
        structure: false,
        selnum: 5,
        show: true,
      });
    } else if (e === 6) {
      setHistory({
        young: true,
        jun: true,
        island: true,
        nugu: true,
        structure: true,
        selnum: 6,
        show: true,
      });
    }
  };
  const handlehistory = () => {
    setHistory({ young: false, jun: false, island: false, nugu: false, structure: false, selnum: 1, show: true });
  };
  const handlehistoryClose = () => {
    setHistory({ young: true, jun: true, island: true, nugu: true, structure: true, selnum: 6, show: false });
  };
  return (
    <>
      <div className={!openNotice.open && !openProject.open && !horizon ? "footer" : "footerHide"}>
        <div className="logo">
          <p className="logoImg">
            <img src="/img/logo2.svg" />
          </p>
        </div>
        {history.show && (
          <p className="closehistory" onClick={() => handlehistoryClose()}>
            <CloseOutlined />
          </p>
        )}
        {!history.show && (
          <p className="openhistory" onClick={() => handlehistory()}>
            <ClockCircleOutlined />
          </p>
        )}
        {history.show && (
          <div className="sliderWrap">
            <Slider
              defaultValue={history.selnum}
              min={1}
              max={6}
              marks={{ 1: "Founded", 2: "Blueprint", 3: "Level Up+", 4: "Nugu-Con", 5: "Level Up+", 6: "Present" }}
              onChange={(e) => handleChange(e)}
            />
          </div>
        )}
      </div>
    </>
  );
}

export default Footer;
