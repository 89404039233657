import React, { useRef, Suspense, useMemo } from "react";
import * as THREE from "three";
import { useFrame } from "react-three-fiber";
import { HTML } from "drei";
import { useStore } from "../../store";

import { useLoader } from "react-three-fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import hatUrl from "./hat.glb";

function Hat({ position, ...props }) {
  const obj = useLoader(GLTFLoader, hatUrl);
  const scene = useMemo(() => {
    return obj?.scene?.clone(true);
  }, [obj]);

  return (
    <Suspense fallback={null}>
      <primitive object={scene} position={position} {...props} />
    </Suspense>
  );
}

function Young(props) {
  const all = useRef();
  const body = useRef();
  const head = useRef();
  const hair = useRef();
  const quest = useRef();
  let timer = 0;
  let pos = new THREE.Vector3(0, 0, 0);
  let vel = new THREE.Vector3(0, 0, 0);
  const setOpenNotice = useStore((state) => state.setOpenNotice);
  const openProject = useStore((state) => state.openProject);
  const openNotice = useStore((state) => state.openNotice);
  const focusObject = useStore((state) => state.focusObject);
  const horizon = useStore((state) => state.horizon);

  useFrame(() => {
    if (all.current) {
      timer += 0.05;
      all.current.position.y = Math.sin(timer) * 0.1;
      all.current.rotation.y = timer * 0.1;
    }
  });

  return (
    <>
      <group onPointerDown={() => setOpenNotice({ open: true, target: "young" })}>
        {!horizon && (
          <HTML
            className={!openNotice.open && !openProject.open ? "contentRichWrap" : "contentRichWrapHide"}
            scaleFactor={18.5}
            position={[0, 12, 0]}
          >
            <div
              className={focusObject !== null ? "contentName Sel" : "contentName UnSel"}
              onPointerDown={() => setOpenNotice({ open: true, target: "young" })}
            >
              <p>CREATIVE DIRECTOR</p>
              <p>Young</p>
            </div>
          </HTML>
        )}
      </group>
      <group ref={all} scale={[0.85, 0.85, 0.85]} onPointerDown={() => setOpenNotice({ open: true, target: "char" })}>
        <Hat position={[0, 5.1, -3]} scale={[0.03, 0.03, 0.03]} rotation={[Math.PI / 6, 0, 0]} />
        <mesh position={[0, 10, 0]} castShadow receiveShadow>
          <sphereBufferGeometry args={[1.5, 20, 20]} attach="geometry" />
          <meshLambertMaterial color="white" attach="material" emissive="white" />
        </mesh>
        <mesh position={[0.5, 9.25, 0.5]} castShadow receiveShadow>
          <sphereBufferGeometry args={[0.7, 20, 20]} attach="geometry" />
          <meshLambertMaterial color="white" attach="material" emissive="white" />
        </mesh>
        <mesh position={[0.5, 9.25, -0.5]} castShadow receiveShadow>
          <sphereBufferGeometry args={[0.7, 20, 20]} attach="geometry" />
          <meshLambertMaterial color="white" attach="material" emissive="white" />
        </mesh>
        <group scale={[0.45, 0.45, 0.45]} position={[0.7, 4.55, 0]}>
          {/* eye hair */}
          <mesh position={[0.75, 10.5, 0]}>
            <boxBufferGeometry args={[1.5, 0.2, 2]} attach="geometry" />
            <meshStandardMaterial color="black" attach="material" />
          </mesh>
          {/* eye */}
          <mesh position={[1.35, 10.1, -0.65]}>
            <boxBufferGeometry args={[0.25, 0.25, 0.25]} attach="geometry" />
            <meshStandardMaterial color="black" attach="material" />
          </mesh>
          <mesh position={[1.35, 10.1, 0.65]}>
            <boxBufferGeometry args={[0.25, 0.25, 0.25]} attach="geometry" />
            <meshStandardMaterial color="black" attach="material" />
          </mesh>
        </group>
      </group>
    </>
  );
}

export default Young;
