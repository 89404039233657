import React, { useRef, useState, useEffect } from "react";
import { useStore } from "../store";
import join from "../components/oris/img/join.svg";
import "../css/top.scss";
const historyData = [
  {
    desc: "Orisandbox was founded by Rich and Duk",
    date: "2020.06.01",
  },
  {
    desc: "KISED funded WarmFinder",
    date: "2020.06.01",
  },
  {
    desc: "Creative Director, Young joined Orisandbox",
    date: "2020.09.01",
  },
  {
    desc: "Orisandbox published Nugu Conference 2020 virtual studio and micro web service.",
    date: "2020.10.31",
  },
  {
    desc: "Developer, Jun joined Orisandbox",
    date: "2020.11.01",
  },
  {
    desc: "Let's make and play, Aventures!",
    date: "",
  },
];
function UnderWater(props) {
  const horizon = useStore((state) => state.horizon);
  const history = useStore((state) => state.history);
  const openProject = useStore((state) => state.openProject);
  const openNotice = useStore((state) => state.openNotice);
  // const handleLink = (e) => {
  //   e.stopPropagation();
  //   console.log("d");
  // };
  return (
    <>
      {!openNotice.open && !openProject.open && !horizon && history.show ? (
        <div className="historydescWrap">
          <p className="historyDesc">
            {historyData[history.selnum - 1].date} {historyData[history.selnum - 1].desc}
          </p>
        </div>
      ) : (
        ""
      )}

      <div className={horizon ? "underWrap" : "underWrapOff"}>
        <div className="underContext">
          <p className="manife">Data is organic, transformative, and interconnected like water.</p>

          <p className="manife">
            Orisandbox creates new value by finding, importing, connecting, and linking large amounts of raw data. This value represents information
            that we didn't expect as it was delivered in personalized insight.
          </p>

          <p className="manife">
            Orisandbox consists of freedom of imagination, expertise in technology, and leadership in action. It constantly researches problems,
            suggests new ideas, and provides convenience to society through skilled technology.
          </p>
          <div className="hideMobile">
            <div className="projectsWrap">
              <p className="projectTitle">WARMFINDER</p>
              <p className="projectDetail">2020.06.01 - present | IoT, ML | Thermal Camera + Data visualization</p>
            </div>

            <div className="projectsWrap">
              <p className="projectTitle">WEAVER</p>
              <p className="projectDetail">2020.06.01 - present | ML, Realtime Feed | Video platform</p>
            </div>

            <div className="projectsWrap">
              <p className="projectTitle">NUGU CONFERENCE 2020</p>
              <p className="projectDetail">2020.10.01 - 2020.10.31 | 3D | Virtual Contents | Client: SK Telecom </p>
            </div>

            <div className="projectsWrap">
              <p className="projectTitle">COSGRAM</p>
              <p className="projectDetail">2020.06.01 - present | Database, ML | Linking Data</p>
            </div>
          </div>

          <div className="projectsWrap">
            <p className="projectTitle">TECH. STACK</p>
            <p className="projectDetail">
              Docker Microservice, flask, node.js, php, react, react-native, redis, MySQL, NoSQL, influxDB, D3.js, Three.js, Data Visualization, IoT,
              arduino, IR camera, raspberryPI, CNN, NLP, Bi-RNN, socket.io, docker-swarm, Kubernetes
            </p>
          </div>

          {/* <p>
          데이터는 물처럼 유기적이고 변화하며 서로간의 관계를 가지고 있습니다. <br></br>
          <br></br> 오리샌드박스는 인지하지 못했던 정보의 연결고리를 발견하고 조합하여 새로운 가치가 있는 정보를 창출합니다.
        </p>

        <p>
          Bottom-level 데이터를 수집하여 상호 연결된 정보를 찾는 데이터베이스에
          <br></br>
          <br></br>
          개별 분산형 IoT를 통한 자발적 위험감지 및 도시 데이터 확보를 위한 열화상 카메라를 연결하여
          <br></br>
          <br></br>
          이용자의 참여를 통해서 새로운 영상으로 탄생하는 공동 참여형 플랫폼을 만듭니다.
        </p>

        <p>
          상상력의 자유로움과 기술의 전문성, 행동하는 리더쉽으로 구성된 우리는 문제점에 대해서 끊임없이 연구하고 새로운 아이디어를 제안하며, 숙련된
          기술을 통해서 사회에 편리함을 제공하고자 합니다.
        </p> */}
        </div>
      </div>
    </>
  );
}

export default UnderWater;
