import React from "react";
import { useStore } from "../../store";
import { HTML } from "drei";

function Nugu() {
  const setOpenProject = useStore((state) => state.setOpenProject);
  const openNotice = useStore((state) => state.openNotice);
  const openProject = useStore((state) => state.openProject);
  const focusObject = useStore((state) => state.focusObject);
  const horizon = useStore((state) => state.horizon);
  return (
    <>
      {!horizon && (
        <group position={[-3.5, 0, -25]} onPointerDown={() => setOpenProject({ open: true })}>
          <HTML className={!openNotice.open && !openProject.open ? "contentRichWrap" : "contentRichWrapHide"} scaleFactor={40} position={[2, 5, 0]}>
            <div className={focusObject !== null ? "contentName Sel" : "contentName UnSel"} onPointerDown={() => setOpenProject({ open: true })}>
              <p>PROJECT</p>
              <p>Nugu</p>
            </div>
          </HTML>
        </group>
      )}
      <group>
        <mesh position={[-1.5, 0, -25]} receiveShadow scale={[5, 5, 5]}>
          <coneBufferGeometry args={[0.75, 1.5, 4]} attach="geometry" />
          <meshPhongMaterial color="#343891" attach="material" />
        </mesh>
      </group>
    </>
  );
}

export default Nugu;
